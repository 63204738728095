const i18nService = {
  defaultLanguage: "gr",

  languages: [
    // {
    //   lang: "gr",
    //   name: "Greek",
    //   flag: process.env.BASE_URL + "media/svg/flags/170-greece.svg"
    // },
    // {
    //   lang: "en",
    //   name: "English",
    //   flag: process.env.BASE_URL + "media/svg/flags/012-uk.svg"
    // }
  ],

  /**
   * Keep the active language in the localStorage
   * @param lang
   */
  setActiveLanguage(lang) {
    localStorage.setItem("language", lang);
  },

  /**
   * Get the current active language
   * @returns {string | string}
   */
  getActiveLanguage() {
    return localStorage.getItem("language") || this.defaultLanguage;
  }
};

export default i18nService;
